.starting {
  margin: 3rem auto;
  text-align: center;
}

.starting h1 {
  font-size: 5rem;
}

h1 {
color: whitesmoke;
}